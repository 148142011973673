import { AppRole } from './roles';
import { AppModules } from 'customizations/constants';

export const CAN_SEE_TOOLBAR = 'CAN_SEE_TOOLBAR';
export const CAN_SEE_MODEL = 'CAN_SEE_MODEL';
export const CAN_SEE_SP_POTENTIAL_MATCHES = 'CAN_SEE_SP_POTENTIAL_MATCHES';
export const CAN_EDIT_SP_POTENTIAL_MATCHES = 'CAN_EDIT_SP_POTENTIAL_MATCHES';
export const CAN_EDIT_BUILDER = 'CAN_EDIT_BUILDER';
export const CAN_EDIT_REGION = 'CAN_EDIT_REGION';
export const CAN_EDIT_CONTRACTOR = 'CAN_EDIT_CONTRACTOR';
export const CAN_EDIT_SUB_DIVISION = 'CAN_EDIT_SUB_DIVISION';
export const CAN_EDIT_SUPPLIER = 'CAN_EDIT_SUPPLIER';
export const CAN_EDIT_NOTES = 'CAN_EDIT_NOTES';
export const CAN_OVERRIDE_CONTRACTOR = 'CAN_OVERRIDE_CONTRACTOR';
export const CAN_REQUEST_RESOURCE = 'CAN_REQUEST_RESOURCE';
export const CAN_TOGGLE_GARAGE = 'CAN_TOGGLE_GARAGE';
export const CAN_APPROVE_PHASE = 'CAN_APPROVE_PHASE';
export const CAN_EDIT_MODEL = 'CAN_EDIT_MODEL';
export const CAN_SEND_EMAIL_CLIENT = 'CAN_SEND_EMAIL_CLIENT';
export const CAN_SIGN_OFF = 'CAN_SIGN_OFF';
export const CAN_ASSIGN_CONTRACTOR = 'CAN_ASSIGN_CONTRACTOR';
export const CAN_APPROVE_BUMPOUT = 'CAN_APPROVE_BUMPOUT';
export const CAN_LOG_HOURS_BUMPOUT = 'CAN_LOG_HOURS_BUMPOUT';
export const CAN_SEE_CONTRACTORS_BUMPOUT = 'CAN_SEE_CONTRACTORS_BUMPOUT';
export const CAN_EDIT_OPTIONS = 'CAN_EDIT_OPTIONS';
export const CAN_OVERRIDE_COUNT = 'CAN_OVERRIDE_COUNT';
export const CAN_PAID_PHASE = 'CAN_PAID_PHASE';
export const CAN_EDIT_PO_RELEASED = 'CAN_EDIT_PO_RELEASED';
export const CAN_EDIT_BUMPOUT_TIMESHEET_PAID =
  'CAN_EDIT_BUMPOUT_TIMESHEET_PAID';
export const CAN_ADD_REPAIR_NOTES = 'CAN_ADD_REPAIR_NOTES';
export const CAN_CHANGE_JOB = 'CAN_CHANGE_JOB';
export const CAN_CHECK_COUNT = 'CAN_CHECK_COUNT';
export const CAN_REQUEST_RESOURCE_ASSIGNED = 'CAN_REQUEST_RESOURCE_ASSIGNED';
export const CAN_CHECK_UNCHECK_HOUSE = 'CAN_CHECK_UNCHECK_HOUSE';
export const CAN_CHECK_HOUSE = 'CAN_CHECK_HOUSE';
export const CAN_EDIT_OPTIONS_JOB_VIEW = 'CAN_EDIT_OPTIONS_JOB_VIEW';
export const CAN_CREATE_REPAIR = 'CAN_CREATE_REPAIR';
export const CAN_CREATE_BOARD = 'CAN_CREATE_BOARD';
export const CAN_CREATE_ROUTE = 'CAN_CREATE_ROUTE';
export const CAN_SEE_SCHEDULE = 'CAN_SEE_SCHEDULE';
export const CAN_CANCEL_PO = 'CAN_CANCEL_PO';
export const CAN_SEE_MATERIALS_DELIVERY = 'CAN_SEE_MATERIALS_DELIVERY';
export const CAN_ADD_MATERIALS_DELIVERY_DATE =
  'CAN_ADD_MATERIALS_DELIVERY_DATE';
export const CAN_CHANGE_EXPECTED_DATE = 'CAN_CHANGE_EXPECTED_DATE';
export const CAN_ADD_PARTIAL_DELIVERY = 'CAN_ADD_PARTIAL_DELIVERY';
export const CAN_STOP_FULFILLMENT = 'CAN_STOP_FULFILLMENT';
export const CAN_PRIORITIZE_MATERIALS_DELIVERY =
  'CAN_PRIORITIZE_MATERIALS_DELIVERY';
export const CAN_CANCEL_DELIVERY_PURCHASE_ORDER =
  'CAN_CANCEL_DELIVERY_PURCHASE_ORDER';
export const CAN_ADD_REPAIR_CONTRACTOR = 'CAN_ADD_REPAIR_CONTRACTOR';
export const CAN_CHANGE_DATES_MASTER_SHEET = 'CAN_CHANGE_DATES_MASTER_SHEET';
export const CAN_IMPORT_JOBS = 'CAN_IMPORT_JOBS';
export const CAN_MARK_SPRAYING_READY_NOW = 'CAN_MARK_SPRAYING_READY_NOW';
export const CAN_SEE_LOT_REPORT = 'CAN_SEE_LOT_REPORT';
export const CAN_TOGGLE_SUBDIVISION_BUMPOUT_TIMESHEET_FLOW =
  'CAN_TOGGLE_SUBDIVISION_BUMPOUT_TIMESHEET_FLOW';
export const CAN_EDIT_BUMPOUT_TIMESHEET_NOTES =
  'CAN_EDIT_BUMPOUT_TIMESHEET_NOTES';
export const CAN_LOG_GARAGE = 'CAN_LOG_GARAGE';

export enum PermType {
  List = 'list',
  Edit = 'edit',
}

export type PermissionType = {
  [PermType.List]?: AppRole[];
  [PermType.Edit]?: AppRole[];
};

export type ModulePermissions = {
  [moduleName: string]: PermissionType;
};

/*
 * Each property represents a module in the app.
 * Each module have a list/edit property that receives an array of roles.
 * The first role in the array has bigger priority and combined roles should be passed
 * as nested arrays.
 * List: User has permissions to view.
 * Edit: User has permission to view and edit.
 */
export const modulesPermissions: ModulePermissions = {
  // Settings
  [AppModules.UserModule]: {
    [PermType.List]: [
      AppRole.SuperAdmin,
      AppRole.Admin,
      AppRole.CustomerServiceRepresentative,
    ],
  },
  [AppModules.RolesModule]: {
    [PermType.List]: [AppRole.SuperAdmin, AppRole.Admin],
  },
  [AppModules.SupplierModule]: {
    [PermType.List]: [
      AppRole.MaterialDispatcher,
      AppRole.RegionalManager,
      AppRole.Supervisor,
      AppRole.LaborCoordinator,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.RegionsModule]: {
    [PermType.List]: [
      AppRole.LaborCoordinator,
      AppRole.SuperAdmin,
      AppRole.Admin,
      AppRole.MaterialDispatcher,
    ],
  },
  [AppModules.BuildersModule]: {
    [PermType.List]: [
      AppRole.LaborCoordinator,
      AppRole.MaterialDispatcher,
      AppRole.SuperAdmin,
      AppRole.Admin,
      AppRole.SupplyProTester,
    ],
  },
  [AppModules.SubDivisionsModule]: {
    [PermType.List]: [
      AppRole.SuperAdmin,
      AppRole.Admin,
      AppRole.MaterialDispatcher,
      AppRole.LaborCoordinator,
      AppRole.CustomerServiceRepresentative,
    ],
  },
  [AppModules.RoutesModule]: {
    [PermType.List]: [AppRole.SuperAdmin, AppRole.Admin],
  },
  [AppModules.ModelsModule]: {
    [PermType.List]: [
      AppRole.SuperAdmin,
      AppRole.Admin,
      AppRole.MaterialDispatcher,
      AppRole.SupplyProTester,
      AppRole.LaborCoordinator,
    ],
  },
  [AppModules.ModelBoardSheetModule]: {
    [PermType.List]: [
      AppRole.MaterialDispatcher,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.BoardModule]: {
    [PermType.List]: [
      AppRole.MaterialDispatcher,
      AppRole.LaborCoordinator,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.ContractorsModule]: {
    [PermType.List]: [
      AppRole.SuperAdmin,
      AppRole.Admin,
      AppRole.MaterialDispatcher,
      AppRole.LaborCoordinator,
    ],
  },
  [AppModules.RepairsSettings]: {
    [PermType.List]: [AppRole.SuperAdmin, AppRole.Admin],
  },

  // General
  [AppModules.MaterialsDeliveryModule]: {
    [PermType.List]: [
      AppRole.MaterialDispatcher,
      AppRole.LaborCoordinator,
      AppRole.SupplierRepresentative,
      AppRole.CustomerServiceRepresentative,
      AppRole.AccountingViewOnly,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.ScheduleModule]: {
    [PermType.List]: [AppRole.Supervisor],
  },
  [AppModules.JobRepairSheetModule]: {
    [PermType.List]: [AppRole.Supervisor],
  },
  [AppModules.MasterSheetModule]: {
    [PermType.List]: [
      AppRole.MaterialDispatcher,
      AppRole.LaborCoordinator,
      AppRole.RegionalManager,
      AppRole.Supervisor,
      AppRole.CustomerServiceRepresentative,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.WarrantySheetModule]: {
    [PermType.List]: [
      AppRole.MaterialDispatcher,
      AppRole.LaborCoordinator,
      AppRole.RegionalManager,
      AppRole.Supervisor,
      AppRole.CustomerServiceRepresentative,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.RMMasterSheetModule]: {
    [PermType.List]: [
      AppRole.RegionalManager,
      AppRole.LaborCoordinator,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.ProdPayMasterSheet]: {
    [PermType.List]: [
      AppRole.CustomerServiceRepresentative,
      AppRole.LaborCoordinator,
      AppRole.AccountingViewOnly,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.RepairReceivablesSheet]: {
    [PermType.List]: [
      AppRole.CustomerServiceRepresentative,
      AppRole.LaborCoordinator,
      AppRole.Supervisor,
      AppRole.AccountingViewOnly,
      AppRole.RegionalManager,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.RepairPayablesSheet]: {
    [PermType.List]: [
      AppRole.Supervisor,
      AppRole.LaborCoordinator,
      AppRole.CustomerServiceRepresentative,
      AppRole.AccountingViewOnly,
      AppRole.RegionalManager,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.JobsModule]: {
    [PermType.List]: [
      AppRole.MaterialDispatcher,
      AppRole.LaborCoordinator,
      AppRole.CustomerServiceRepresentative,
      AppRole.AccountingViewOnly,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.PendingJobsModule]: {
    [PermType.List]: [
      AppRole.SupplyProTester,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.PreRockSheet]: {
    [PermType.List]: [
      AppRole.MaterialDispatcher,
      AppRole.RegionalManager,
      AppRole.Supervisor,
      AppRole.CustomerServiceRepresentative,
      AppRole.LaborCoordinator,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
  [AppModules.BumpoutTimesheetsModule]: {
    [PermType.List]: [
      AppRole.Supervisor,
      AppRole.Bumper,
      AppRole.CustomerServiceRepresentative,
      AppRole.LaborCoordinator,
      AppRole.SuperAdmin,
      AppRole.Admin,
    ],
  },
};
