import { EditorState } from 'draft-js';
import { useEffect, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';
import {
  BuilderDto,
  ModelDto,
  SubdivisionDto,
} from '../../../../../common/dist';
import { INITIAL_JOB_CREATE } from '../constants';
import { JobCreateFormFields } from '../types';
import { DataProvider } from 'react-admin';
import dataProvider from 'providers/dataProvider';
import {
  findManyBuilders,
  findManyModels,
  findManySubdivisions,
} from 'services/jobs';

const ZIP_CODE_REGEX = new RegExp(/^\d{5}(-\d{4})?$/);

const useJobCreate = () => {
  const [buildingId, setBuildingId] = useState<string | null>('');
  const [buildingName, setBuildingName] = useState('');
  const [toggleBuilding, setToggleBuilding] = useState(true);
  const [options, setOptions] = useState(EditorState.createEmpty());
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState<JobCreateFormFields>(INITIAL_JOB_CREATE);
  const [builders, setBuilders] = useState<BuilderDto[] | null>(null);
  const [subdivisions, setSubdivisions] = useState<SubdivisionDto[] | null>(
    null,
  );
  const [models, setModels] = useState<ModelDto[] | null>(null);

  const builderOptions = useMemo(() => {
    return (
      builders?.map(builder => ({
        id: builder.id,
        name: builder.name,
      })) ?? []
    );
  }, [builders]);

  const subdivisionOptions = useMemo(() => {
    return (
      subdivisions?.map(builder => ({
        id: builder.id,
        name: builder.name,
      })) ?? []
    );
  }, [subdivisions]);

  const modelOptions = useMemo(() => {
    return (
      models?.map(builder => ({
        id: builder.id,
        name: `${builder.name} (${builder.externalId})`,
      })) ?? []
    );
  }, [models]);

  const currentModel = useMemo(
    () => models?.find(model => model.id === fields.model),
    [fields, models],
  );
  const inBuilding = !!currentModel?.inBuilding;

  const validateFields = () => {
    const newErrors = {};

    // Model Filled
    if (!fields.model) {
      newErrors['model'] = 'The Model is Required';
    }
    // Community Phase
    if (!fields.communityPhase) {
      newErrors['communityPhase'] = 'The Community Phase is Required';
    }

    if (inBuilding && !buildingName && !buildingId) {
      newErrors['building'] = 'The Building is Required';
    }

    if (!fields.address1) {
      newErrors['address1'] = 'The Address is Required';
    }
    if (!fields.city) {
      newErrors['city'] = 'The City is Required';
    }
    if (!fields.state) {
      newErrors['state'] = 'The State is Required';
    }
    if (!fields.zip) {
      newErrors['zip'] = 'The Zip Code is Required';
    } else {
      if (!fields.zip.match(ZIP_CODE_REGEX)) {
        newErrors['zip'] = [
          'Zip code must be entered either in 5 digit format (12345) or 9 digit format (12345-1234)',
        ];
      }
    }
    if (!fields.idNumber) {
      newErrors['idNumber'] = 'The Job ID Code is Required';
    }

    if (Object.keys(newErrors).length) {
      // Building or BuildingId
      return {
        valid: false,
        errors: newErrors,
      };
    } else {
      return {
        valid: true,
        errors: {},
      };
    }
  };

  const getSupervisor = async (
    dataProvider: DataProvider,
    supervisorUserId?: string,
  ) => {
    if (!supervisorUserId) return null;

    const userData = await dataProvider.getOne('user', {
      id: supervisorUserId,
    });

    const supervisorName = `${userData.data.firstName} ${userData.data.lastName}`;

    return supervisorName;
  };

  const getBuildingData = () => {
    const hasBuildingNameOrBuildingId = buildingName || buildingId;

    if (!hasBuildingNameOrBuildingId) return null;

    if (toggleBuilding) {
      return {
        buildingId,
      };
    }

    return {
      building: {
        number: buildingName,
        subdivisionId: fields.subdivision,
      },
    };
  };

  const getBuilders = async () => {
    const builders = await findManyBuilders(dataProvider);

    if (builders) {
      setBuilders(builders);
    }
  };

  const getSubdivisions = async (builderId: string) => {
    const subdivisions = await findManySubdivisions(dataProvider, {
      builderId,
      active: true,
    });

    if (subdivisions) {
      setSubdivisions(subdivisions);
    }
  };

  const getModels = async (subdivisionId: string) => {
    const models = await findManyModels(dataProvider, {
      subdivisionId,
      active: true,
    });

    if (models) {
      setModels(models);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await getBuilders();
    };

    initialize();
  }, []);

  return {
    fields,
    options,
    buildingId,
    toggleBuilding,
    getSubdivisions,
    getModels,
    builders,
    builderOptions,
    buildingName,
    subdivisions,
    subdivisionOptions,
    models,
    modelOptions,
    errors,
    setErrors,
    setBuildingName,
    validateFields,
    getSupervisor,
    getBuildingData,
    setToggleBuilding,
    setBuildingId,
    setOptions,
    setFields,
  };
};

export const JobCreateContainer = createContainer(useJobCreate);
