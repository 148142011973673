import { useState } from 'react';

import { InjectedFieldProps, PublicFieldProps } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import { BumpResourceSheetDto } from '@vatos-pas/common';
import NotesIcon from '@material-ui/icons/Note';

import { Show } from 'components/Show';
import { CAN_EDIT_BUMPOUT_TIMESHEET_NOTES } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { NotesManagementDialog } from './NotesManagementDialog';

type NotesButtonProps = PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const NotesButton = ({ record }: NotesButtonProps) => {
  if (!record?.id) return null;

  const { hasPermission } = PermissionsProvider.useContainer();

  const [isNotesManagementOpen, setIsNotesManagementOpen] = useState(false);

  const canEditNotes = hasPermission(CAN_EDIT_BUMPOUT_TIMESHEET_NOTES);

  return (
    <Show condition={canEditNotes}>
      <IconButton
        color="primary"
        size="small"
        onClick={() => setIsNotesManagementOpen(true)}
      >
        <NotesIcon />
      </IconButton>
      <NotesManagementDialog
        open={isNotesManagementOpen}
        record={record}
        handleClose={() => setIsNotesManagementOpen(false)}
      />
    </Show>
  );
};

export default NotesButton;
