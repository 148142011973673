import React, { useState, Fragment, useEffect, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Create, CreateProps, useDataProvider, useNotify } from 'react-admin';
import SimpleForm from 'components/SimpleForm';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import { createJob, findOneJob } from 'services/jobs';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { convertToHTML } from 'draft-convert';
import InfoDialog from 'components/InfoDialog';
import { SubdivisionDto } from '@vatos-pas/common';
import { ProjectDetailsCreate } from '../components/section-project-details-create';
import { useContainer } from 'unstated-next';
import { JobCreateContainer } from '../providers/job-create';
import { SpFieldMapping } from '../components/section-sp-field-mapping';
import { EnhancedJobCreateDto } from '../types';
import { ImportedJobContainer } from '../providers/imported-job';

const JobsCreate = (props: CreateProps) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const {
    fields,
    setFields,
    options,
    getSupervisor,
    validateFields,
    setBuildingId,
    getBuildingData,
    setErrors,
    getModels,
    getSubdivisions,
  } = useContainer(JobCreateContainer);

  const {
    getImportedJob,
    getBoardTypes,
    importedJob,
    getSpMappingPayload,
    isBuilderMatchActive,
    isModelMatchInactive,
    isSubdivisionMatchActive,
  } = useContainer(ImportedJobContainer);

  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [loadingImportedJob, setLoadingImportedJob] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [infoContent, setInfoContent] = useState('');
  const duplicateRef = props.location?.search.split('duplicateRef=')[1];
  const spOrderIdRef = props.location?.search.split('spOrderId=')[1];

  const handleDuplicateJob = async (id: string) => {
    const job = await findOneJob(dataProvider, id);

    if (!job || !job.model) return;

    const subdivision = await dataProvider.getOne<SubdivisionDto>(
      'subdivision',
      {
        id: job.model.subdivisionId,
      },
    );

    if (subdivision.data.builderId) {
      await getSubdivisions(subdivision.data.builderId);
    }

    if (job.modelId) {
      await getModels(subdivision.data.id);
    }

    const supervisorName = await getSupervisor(
      dataProvider,
      subdivision.data.supervisorUserId,
    );

    const { communityPhase } = job;

    setBuildingId(job.buildingId);
    setFields({
      ...fields,
      ...(supervisorName && { supervisor: supervisorName }),
      communityPhase,
      regionId: subdivision.data.regionId,
      builder: subdivision.data.builderId,
      subdivision: subdivision.data.id,
      model: job.modelId,
    });
  };

  const handleCreateJob = (props: CreateProps) => async () => {
    const validation = validateFields();

    if (!validation.valid) {
      return setErrors(validation.errors);
    }

    setLoading(true);

    const spMappingPayload = getSpMappingPayload();

    const buildingPayload = getBuildingData();

    const {
      model: modelId,
      block,
      builder: _removedBuilder,
      buildingName: _removedBuildingName,
      subdivision: _removedSubdivision,
      ...restFields
    } = fields;

    const isEmptyBlock = typeof block === 'string' && block.length === 0;

    const jobPayload: EnhancedJobCreateDto = {
      modelId,
      block: isEmptyBlock ? undefined : block,
      options: convertToHTML(options.getCurrentContent()),
      ...restFields,
      ...(buildingPayload && buildingPayload),
      ...(spOrderIdRef &&
        spMappingPayload && {
          spMapping: {
            spOrderId: spOrderIdRef,
            ...spMappingPayload,
          },
        }),
    };

    try {
      const jobCreated = await createJob(dataProvider, jobPayload);
      setAlertMessage('Job Created!');
      setOpenSnackBar(true);
      setLoading(false);
      setTimeout(() => setOpenSnackBar(false), 3000);
      if (duplicateRef) {
        props.history?.push(
          '/job-sheet/' +
            jobCreated.data.id +
            '/show?duplicateRef=' +
            duplicateRef,
        );
      } else {
        props.history?.push('/job-sheet/' + jobCreated.data.id + '/show');
      }
    } catch (error: any) {
      setLoading(false);
      if (error && error.message) {
        setAlertMessage(error.message);
      } else {
        setAlertMessage('Job Creation Failed!');
      }
      setOpenSnackBar(true);
      setTimeout(() => setOpenSnackBar(false), 3000);
    }
  };

  const handleImportJob = async (orderId: string) => {
    setLoadingImportedJob(true);
    try {
      const importedJobFields = await getImportedJob(orderId);
      setFields(prevState => ({
        ...prevState,
        ...importedJobFields,
      }));
      if (importedJobFields?.builder) {
        await getSubdivisions(importedJobFields.builder);
      }
      if (importedJobFields?.subdivision) {
        await getModels(importedJobFields.subdivision);
      }
      await getBoardTypes();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error?.response?.data?.message
      ) {
        notify(`Job Import Error: ${error.response.data.message}`, 'warning');
      } else {
        notify(`Job Import Error: ${error.message}`, 'warning');
      }
    } finally {
      setLoadingImportedJob(false);
    }
  };

  const handleOpenInfoModal = (message: string) => {
    setInfoContent(message);
    setOpenInfo(true);
  };

  const isAnyMatchInactive =
    isBuilderMatchActive || isSubdivisionMatchActive || isModelMatchInactive;

  useEffect(() => {
    const initialize = async () => {
      if (duplicateRef) {
        await handleDuplicateJob(duplicateRef);
        return;
      }

      if (spOrderIdRef) {
        handleImportJob(spOrderIdRef);
      }
    };

    initialize();
  }, []);

  return (
    <Fragment>
      <Create {...props} className={classes.createBox}>
        <SimpleForm hideTollBar>
          {importedJob && (
            <SpFieldMapping
              handleOpenInfoModal={handleOpenInfoModal}
              resource={props.resource}
            />
          )}
          <Box className={classes.flexRight}>
            <Typography variant="body1" gutterBottom className={classes.bold}>
              {importedJob
                ? 'Preliminary Takeoff Report'
                : 'New Project - Take Off Report'}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={loading ? <Fragment /> : <SaveIcon />}
              onClick={handleCreateJob(props)}
              disabled={loading || isAnyMatchInactive || loadingImportedJob}
            >
              {loading ? (
                <CircularProgress
                  classes={{ colorPrimary: classes.spinner }}
                  size={24}
                />
              ) : importedJob ? (
                'Import Job'
              ) : (
                'Save'
              )}
            </Button>
          </Box>
          <Box className={classes.flex}>
            <Box className={classes.leftPanel}>
              <ProjectDetailsCreate />
            </Box>
            <Box className={classes.rightPanel}>
              <Box
                borderRadius={5}
                borderColor="lightgray"
                display="flex"
                justifyContent="center"
                alignItems="center"
                pt={10}
                px={3}
                className={classes.pointer}
              >
                <Typography className={classes.poText}>
                  {`Create the project in order to submit purchase orders`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </SimpleForm>
      </Create>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackBar}
        message={alertMessage}
        key={'bottomcenter'}
      />
      <InfoDialog
        title="Stock Specs"
        content={infoContent}
        open={openInfo}
        handleClose={() => setOpenInfo(false)}
      />
    </Fragment>
  );
};

export const JobsCreateWithProvider: FC<CreateProps> = props => {
  return (
    <JobCreateContainer.Provider>
      <ImportedJobContainer.Provider>
        <JobsCreate {...props} />
      </ImportedJobContainer.Provider>
    </JobCreateContainer.Provider>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '8px 0px',
  },
  inputIntegration: {
    margin: '10px 0px',
  },
  inputDate: {
    margin: '8px 0px 15px',
  },
  inputAddress: {
    margin: '15px 0px 0px',
  },
  inputNoMarginRight: {
    margin: '8px 0px 8px 15px',
  },
  inputMarginRight: {
    margin: '15px 15px 15px 0px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    marginTop: '25px',
    maxWidth: '1500px',
    display: 'flex',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  thirdWidth: {
    width: '30%',
    margin: '0px 15px',
  },
  leftPanel: {
    width: '70%',
  },
  rightPanel: {
    marginLeft: '20px',
    width: '30%',
    border: '1.5px solid lightgray',
    padding: '15px',
    borderRadius: '10px',
  },
  grayBox: {
    border: '1.5px solid lightgray',
    borderRadius: '10px',
    padding: '25px',
    marginBottom: '5px',
  },
  flex: {
    display: 'flex',
    maxWidth: '1500px',
    width: '1500px',
  },
  button: {
    marginLeft: 'auto',
  },
  flexRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100% !important',
    margin: '10px 0px',
  },
  bold: {
    fontWeight: 'bold',
  },
  floorBoard: {
    backgroundColor: '#efefef',
    borderRadius: '10px',
    padding: '10px',
  },
  addFloor: {
    display: 'flex',
    width: 'calc(100% - 20px)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '8px',
    padding: '10px',
  },
  pointer: {
    cursor: 'pointer',
  },
  hide: {
    display: 'none',
  },
  spinner: {
    color: 'white',
  },
  poText: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    fontStyle: 'italic',
  },
  editor: {
    border: '1px solid gray',
    padding: '3px',
  },
  infoWarning: {
    color: '#ff9800',
  },
});
