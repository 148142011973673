import React, { ChangeEvent, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Editor } from 'react-draft-wysiwyg';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CustomReference from 'components/CustomReference';
import InfoIcon from '@material-ui/icons/Info';
import State from 'components/State/Custom';
import { useContainer } from 'unstated-next';
import { JobCreateContainer } from '../providers/job-create';
import { ImportedJobContainer } from '../providers/imported-job';
import { Show } from 'components/Show';
import { getFieldValidation, getFilterBuilding } from '../utils';
import { JobCreateFormFields } from '../types';
import CustomSelect from 'components/CustomSelect';

const ZIP_CODE_REGEX = new RegExp(/^\d{5}(-\d{4})?$/);

type FieldsPayload = {
  [key: string]: string;
};

export const ProjectDetailsCreate: FC = () => {
  const {
    setFields,
    errors,
    fields,
    options,
    setOptions,
    toggleBuilding,
    setToggleBuilding,
    buildingId,
    setBuildingId,
    buildingName,
    setBuildingName,
    builderOptions,
    subdivisionOptions,
    modelOptions,
    getSubdivisions,
    getModels,
  } = useContainer(JobCreateContainer);

  const { importedJob } = useContainer(ImportedJobContainer);

  const classes = useStyles();

  const ComponentIconBuilding = toggleBuilding
    ? AddCircleIcon
    : RemoveCircleIcon;

  const showImported =
    !!importedJob?.modelId ||
    !!importedJob?.subdivisionId ||
    !!importedJob?.builderId;

  const onChangeFields =
    (currentField: keyof JobCreateFormFields, payload?: FieldsPayload) =>
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      if (event.target.value === fields[currentField]) return;

      setFields(prevState => ({
        ...prevState,
        [currentField]: event.target.value,
        ...payload,
      }));

      // Refetch subdivisions if we're updating builderId
      if (currentField === 'builder') {
        getSubdivisions(event.target.value as string);
      }

      // Refetch models if we're updating subdivisionId
      if (currentField === 'subdivision') {
        getModels(event.target.value as string);
      }
    };

  return (
    <Box width="100%">
      <Box className={classes.grayBox}>
        <Typography variant="body1" gutterBottom className={classes.bold}>
          Project Details
        </Typography>
        <Box width="100%" display="flex" mt={2}>
          <Box width="50%" paddingRight={3}>
            <Box>
              <Show condition={!importedJob || !!fields.builder}>
                <CustomSelect
                  resource="builder"
                  label="Builder"
                  options={builderOptions}
                  value={fields.builder}
                  onChange={onChangeFields('builder', {
                    subdivision: '',
                    model: '',
                  })}
                  disabled={showImported || (!!fields.builder && !!importedJob)}
                />
              </Show>
              <Show condition={!!importedJob && !fields.builder}>
                <Box
                  display="flex"
                  borderBottom="1px dotted gray"
                  marginBottom="14px"
                  marginTop="31px"
                >
                  <Typography>Undefined</Typography>
                  <InfoIcon color="error" />
                </Box>
              </Show>

              <Show condition={!importedJob || !!fields.subdivision}>
                <CustomSelect
                  resource="subdivision"
                  label="Subdivision"
                  options={subdivisionOptions}
                  value={fields.subdivision}
                  onChange={onChangeFields('subdivision', {
                    model: '',
                  })}
                  disabled={
                    showImported ||
                    (!!fields.subdivision && !!importedJob) ||
                    !fields.builder
                  }
                />
              </Show>
              <Show condition={!!importedJob && !fields.subdivision}>
                <Box
                  display="flex"
                  borderBottom="1px dotted gray"
                  marginBottom="14px"
                  marginTop="46px"
                >
                  <Typography>Undefined</Typography>
                  <InfoIcon color="error" />
                </Box>
              </Show>

              <Show condition={!importedJob || !!fields.model}>
                <CustomSelect
                  resource="model"
                  label="Model"
                  options={modelOptions}
                  value={fields.model}
                  onChange={onChangeFields('model')}
                  required
                  disabled={
                    showImported ||
                    (!!fields.model && !!importedJob) ||
                    !fields.subdivision
                  }
                  error={!!getFieldValidation(errors, 'model')}
                  helperText={getFieldValidation(errors, 'model')}
                />
              </Show>

              <Show condition={!!importedJob && !fields.model}>
                <Box
                  display="flex"
                  borderBottom="1px dotted gray"
                  marginBottom="14px"
                  marginTop="46px"
                >
                  <Typography>Undefined</Typography>
                  <InfoIcon color="error" />
                </Box>
              </Show>

              <Box display="flex" alignItems="center">
                <Show condition={toggleBuilding}>
                  <CustomReference
                    value={buildingId ?? undefined}
                    label="Building"
                    resource="building"
                    sort={{ field: 'number', order: 'ASC' }}
                    customItemName="number"
                    disabled={!fields.subdivision}
                    onChange={event => {
                      setBuildingId(event.target.value as string);
                    }}
                    filters={getFilterBuilding(fields.subdivision)}
                    error={!!getFieldValidation(errors, 'building')}
                    helperText={getFieldValidation(errors, 'building')}
                  />
                </Show>
                <Show condition={!toggleBuilding}>
                  <TextField
                    label="Building #"
                    fullWidth
                    disabled={!fields.subdivision}
                    className={classes.input}
                    value={buildingName}
                    onChange={event => setBuildingName(event.target.value)}
                    error={!!getFieldValidation(errors, 'building')}
                    helperText={getFieldValidation(errors, 'building')}
                  />
                </Show>

                <ComponentIconBuilding
                  titleAccess="Add new Building"
                  className={classes.pointer}
                  onClick={() => setToggleBuilding(!toggleBuilding)}
                />
                <TextField
                  label="Lot"
                  value={fields.lot}
                  fullWidth
                  disabled={!!importedJob?.spOrderJobLot}
                  className={classes.inputNoMarginRight}
                  onChange={onChangeFields('lot')}
                />
              </Box>
              <TextField
                label="Block"
                fullWidth
                className={classes.input}
                disabled={!!importedJob && !importedJob.spOrderJobBlock}
                value={fields.block ?? ''}
                onChange={onChangeFields('block')}
                error={!!getFieldValidation(errors, 'block')}
                helperText={getFieldValidation(errors, 'block')}
              />
              <TextField
                label="Community Phase"
                fullWidth
                className={classes.input}
                value={fields.communityPhase}
                onChange={onChangeFields('communityPhase')}
                error={!!getFieldValidation(errors, 'communityPhase')}
                helperText={getFieldValidation(errors, 'communityPhase')}
              />
              <TextField
                value={fields.supervisor}
                label="Supervisor"
                fullWidth
                disabled
                className={classes.input}
              />
            </Box>
          </Box>
          <Box width="50%">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Project Approval Date"
              fullWidth
              disabled
              className={classes.inputDate}
              value={null}
              onChange={date => date}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              className={classes.inputDate}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Project Due Date"
              fullWidth
              disabled
              value={null}
              onChange={date => date}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <TextField
              className={classes.input}
              fullWidth
              label="Job ID #"
              disabled={!!importedJob?.spOrderBuilderOrderNumber}
              value={fields.idNumber}
              onChange={onChangeFields('idNumber')}
              error={!!getFieldValidation(errors, 'idNumber')}
              helperText={getFieldValidation(errors, 'idNumber')}
            />
            <TextField
              className={classes.inputAddress}
              fullWidth
              value={fields.address1}
              label="Address"
              disabled={!!importedJob?.spOrderJobStreet}
              onChange={onChangeFields('address1')}
              error={!!getFieldValidation(errors, 'address1')}
              helperText={getFieldValidation(errors, 'address1')}
            />
            <Box display="flex">
              <TextField
                className={classes.inputMarginRight}
                fullWidth
                label="Address2"
                disabled={!!importedJob?.spOrderJobStreetSupplement}
                value={fields.address2}
                onChange={onChangeFields('address2')}
              />
              <TextField
                className={classes.inputAddress}
                fullWidth
                label="Zip"
                value={fields.zip}
                disabled={
                  !!importedJob?.spOrderJobPostalCode?.match(ZIP_CODE_REGEX)
                }
                onChange={onChangeFields('zip')}
                error={!!getFieldValidation(errors, 'zip')}
                helperText={getFieldValidation(errors, 'zip')}
              />
            </Box>
            <Box display="flex">
              <TextField
                className={classes.inputMarginRight}
                fullWidth
                label="City"
                disabled={!!importedJob?.spOrderJobCity}
                value={fields.city}
                onChange={onChangeFields('city')}
                error={!!getFieldValidation(errors, 'city')}
                helperText={getFieldValidation(errors, 'city')}
              />
              <State
                onChange={event => {
                  setFields({
                    ...fields,
                    ['state']: event.target.value as string,
                  });
                }}
                disabled={importedJob?.spOrderJobStateCode === 'USFL'}
                value={fields.state}
                error={getFieldValidation(errors, 'state')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.grayBox}>
        <Typography variant="body1" gutterBottom className={classes.bold}>
          Options
        </Typography>
        <Box>
          <Editor
            editorState={options}
            editorClassName={classes.editor}
            toolbar={{
              options: ['inline', 'blockType', 'list'],
              fontSize: {
                options: [12, 14, 16, 18, 24, 30],
              },
              inline: {
                options: ['bold', 'italic', 'underline'],
              },
              blockType: {
                inDropdown: false,
                options: ['Normal', 'H1', 'H2', 'H3'],
              },
              list: {
                options: ['unordered', 'ordered'],
              },
            }}
            onEditorStateChange={value => setOptions(value)}
          />
        </Box>
      </Box>
      <Show condition={!!importedJob}>
        <Box className={classes.grayBox}>
          <Typography variant="body1" gutterBottom className={classes.bold}>
            Other SupplyPro Information
          </Typography>
          <Box>
            <TextField
              className={classes.inputMarginRight}
              fullWidth
              label="Group Description"
              disabled
              value={importedJob?.spOrderJobGroupDescription}
            />
          </Box>
        </Box>
      </Show>
    </Box>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '8px 0px',
  },
  inputDate: {
    margin: '8px 0px 15px',
  },
  inputAddress: {
    margin: '15px 0px 0px',
  },
  inputNoMarginRight: {
    margin: '8px 0px 8px 15px',
  },
  inputMarginRight: {
    margin: '15px 15px 15px 0px',
  },
  grayBox: {
    border: '1.5px solid lightgray',
    borderRadius: '10px',
    padding: '25px',
    marginBottom: '5px',
  },
  button: {
    marginLeft: 'auto',
    marginRight: 20,
    backgroundColor: '#4caf51',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4caf51',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  pointer: {
    cursor: 'pointer',
  },
  editor: {
    border: '1px solid gray',
    padding: '3px',
  },
});
